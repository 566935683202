import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Cards from "../components/knowledgeCentre/Cards";
import Layout from "../components/Layout";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Research Papers",
  description:
    "Read the latest research papers from Plastometrex - covering applications and developments assocaited with Profilometry-based Indentation Plastometry (PIP). ",
};

function ResearchPapersPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/researchPapers/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              description
              pdf {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return (
    <KnowledgeCentrePage seo={seo} linkPath="/research-papers">
      <Cards
        title={"Research Papers"}
        byline={"See our latest peer-reviewed publications."}
        data={data}
      />
    </KnowledgeCentrePage>
  );
}

export default ResearchPapersPage;
